import * as Skeleton from './skeleton-loader/skeleton-loader';
import styles from './styles/loading.module.scss';

interface Props {
    header?: boolean;
    singlePageTop?: boolean;
    overlay?: boolean;
    overlayFixed?: boolean;
    bgWhite?: boolean;
    pagesStyle?: {
        cartStyle?: boolean;
        notifyStyle?: boolean;
        orderDetails?: boolean;
        orderList?: boolean;
        productCard?: boolean;
        rowClass?: boolean;
        count?: number;
        categorySidebar?: boolean;
        checkoutStyle?: boolean;
        productCardHome?: boolean;
        accountStyle?: boolean;
        productList?: boolean;
    };
    single?: {
        line?: boolean;
        width?: string;
        height?: string;
        margin?: string;
    };
}

const Loading = ({ single, singlePageTop, header, pagesStyle, overlay, overlayFixed, bgWhite }: Props) => {
    if (single?.line) {
        return <Skeleton.SkeletonLine width={single?.width} height={single?.height} margin={single?.margin} />;
    }
    if (header) {
        return <Skeleton.SkeletonHeader />;
    }
    if (pagesStyle?.cartStyle) {
        return <Skeleton.SkeletonCartPage />;
    }
    if (pagesStyle?.checkoutStyle) {
        return <Skeleton.SkeletonCheckoutPage />;
    }
    if (pagesStyle?.accountStyle) {
        return <Skeleton.SkeletonAccountPage />;
    }
    if (pagesStyle?.notifyStyle) {
        return <Skeleton.SkeletonNotifyPage />;
    }
    if (pagesStyle?.orderList) {
        return <Skeleton.SkeletonOrderList />;
    }
    if (pagesStyle?.orderDetails) {
        return <Skeleton.SkeletonOrderDetails />;
    }
    if (pagesStyle?.productCard) {
        return <Skeleton.SkeletonProductCard count={pagesStyle?.count} rowClass={pagesStyle?.rowClass} />;
    }
    if (pagesStyle?.productList) {
        return <Skeleton.SkeletonProductList count={pagesStyle?.count ? pagesStyle?.count : 5} />;
    }
    if (pagesStyle?.productCardHome) {
        return <Skeleton.SkeletonProductCardHome count={pagesStyle?.count} rowClass={pagesStyle?.rowClass} />;
    }
    if (pagesStyle?.categorySidebar) {
        return <Skeleton.SkeletonCatSidebar count={pagesStyle?.count} />;
    }
    if (singlePageTop) {
        return <Skeleton.SkeletonSinglePageTop />;
    }
    if (overlay) {
        return (
            <div className={styles.overlay_wrap}>
                <div className={styles.overlay_bg}>&nbsp;</div>
                <div className={styles.overlay}>
                    <span className={styles.loader}></span>
                    <p>Please Wait...</p>
                </div>
            </div>
        );
    }
    if (overlayFixed) {
        return (
            <div className={`${styles.overlay_wrap_fixed} ${bgWhite ? styles.bg_white : ''}`}>
                <div className={styles.overlay_bg}>&nbsp;</div>
                <div className={styles.overlay}>
                    <span className={styles.loader}></span>
                    <p>Please Wait...</p>
                </div>
            </div>
        );
    }
    return (
        <div>
            <div className={styles.loader_wrp}>
                <span className={styles.loader}></span>
            </div>
        </div>
    );
};

export default Loading;
